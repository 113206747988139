<template>
  <div class="row no-gutters">
    <div class="col-12">
      <div class="row no-gutters">
        <div class="col-10 offset-1 col-lg-12 offset-lg-0">
          <div class="m-card text-center">
            <h1 class="o-heading o-heading--xl">
              {{ $t("menu_voices.welcome") }} {{ userData.firstName }}
              {{ userData.lastName }}
            </h1>
            <p>
              {{ $t("addMinore.title") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="o-step pt-4">
      <div class="o-step__point pt-3 pt-md-0">
        <div class="d-flex flex-column align-items-center">
          <b-img-lazy
            src="../../assets/Operatore.svg"
            alt="operatore"
          ></b-img-lazy>
          <p class="mt-2">{{ $t("addMinore.step_0") }}</p>
        </div>

        <div class="o-step__line ">
          <span></span><span></span><span></span><span></span> <span></span
          ><span></span>
        </div>
      </div>
      <div class="o-step__line o-step__line--vertical">
        <span></span><span></span><span></span>
      </div>
      <div class="o-step__point o-step__point--column pt-3 pt-md-0">
        <div class="o-step__duble">
          <b-img-lazy src="../../assets/Code.svg" alt="step_1_a"></b-img-lazy>
          <p class="ml-3 mt-2 mt-md-0">
            <strong>{{ $t("addMinore.step_1_a") }}</strong>
          </p>
        </div>
        <div class="o-step__duble">
          <b-img-lazy src="../../assets/Code.svg" alt="step_1_b"></b-img-lazy>
          <p class="ml-3 mt-2 mt-md-0">
            <strong>{{ $t("addMinore.step_1_b") }}</strong>
          </p>
        </div>
      </div>
      <div class="o-step__line o-step__line--vertical">
        <span></span><span></span><span></span>
      </div>
      <div class="o-step__point text-left pt-3 pt-md-0">
        <b-img-lazy src="../../assets/data.svg" alt="operatore"></b-img-lazy>
        <p class="ml-3">
          <strong>{{ $t("addMinore.step_2") }}</strong>
        </p>
      </div>
    </div>
    <div class="col-10 offset-1 col-lg-6 offset-lg-3 pt-5 pt-lg-0 mb-3 mb-lg-0">
      <p class="text-center">
        <strong>{{ $t("addMinore.dati") }}</strong>
      </p>
      <div class="m-card ">
        <div class="m-card__form">
          <b-form-group
            id="codice"
            :label="this.$t('addMinore.attivazione')"
            label-for="codice-input"
            class="m-input"
          >
            <b-form-input
              id="codice-input"
              v-model="data.codice"
              type="text"
              :state="this.$isPassword(data.codice)"
              :placeholder="this.$t('addMinore.attivazione_placeholder')"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="mps"
            :label="this.$t('addMinore.MSP')"
            label-for="MSP-input"
            class="m-input"
          >
            <b-form-input
              id="mps-input"
              v-model="data.mps"
              type="text"
              :state="this.$isPassword(data.mps)"
              :placeholder="this.$t('addMinore.MSP_placeholder')"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="nascita"
            :label="this.$t('addMinore.nascita')"
            label-for="nascita-input"
            class="m-input"
          >
            <b-form-input
              id="nascita-input"
              v-model="data.nascita"
              type="data"
              :state="this.$isPassword(data.nascita)"
              :placeholder="this.$t('addMinore.nascita_placeholder')"
              required
            ></b-form-input>
          </b-form-group>
          <div class="m-card__action mt-2">
            <b-button
              class="ml-lg-3 mb-1"
              variant="outline-primary"
              size="md"
              >{{ $t("generic.annulla") }}</b-button
            >
            <b-button
              class="ml-lg-3 mb-1 text-light "
              variant="secondary"
              size="md"
              >{{ $t("addMinore.action") }}</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppAddMinore",
  props: ["userData"],

  data() {
    return {
      passwordHide: "*********",

      data: [],
    };
  },
  computed: {},

  methods: {},
};
</script>
